<template>
  <b-row v-if="history.length > 0">
    <b-col cols="12">
      <b-card class="my-card m-0">
        <b-table
          striped
          small
          id="myTables"
          bordered
          selectable
          selected-variant="primary"
          select-mode="single"
          responsive="sm"
          :items="history"
          :fields="fields"
          head-row-variant="secondary"
          @row-clicked="clicked"
        >
          <template #cell(checkbox)="row">
            <b-form-checkbox
              v-model="row.detailsShowing"
              plain
              class="vs-checkbox-con"
              @change="row.toggleDetails"
            >
            </b-form-checkbox>
          </template>
          <template #cell(is_active)="data">
            <span v-if="data.item.is_active === 1"> Свободен </span>
            <span v-else> Занять </span>
          </template>
          <template #cell(performer_full_name)="data">
            <span @dblclick="getPerformer(data)" class="reference-field">
              {{ data.item.performer_full_name }}
            </span>
          </template>
          <template #cell(performer_transport_model)="data">
            <span @dblclick="getDriver(data)" class="reference-field">
              {{ data.item.performer_transport_model }}
            </span>
          </template>
          <template #cell(filterAfto)="data">
            <span @dblclick="getFilterAfto(data)" class="reference-field">
              (***)
            </span>
          </template>
        </b-table>
      </b-card>
    </b-col>
  </b-row>
</template>

<script>
import { resizeable } from "@/utils/TableFunctions/resizeable.js";
import { cellSelect } from "@/utils/TableFunctions/cellSelect";
export default {
  props: ["history"],
  data() {
    return {
      fields: [
        { key: "checkbox", label: "Флаг" },
        {
          key: "division",
          label: "Подразделение",
          sortable: true,
          thStyle: { width: "150px" },
        },
        {
          key: "performer_login",
          label: "Логин",
          sortable: true,
          thStyle: { width: "150px" },
        },
        {
          key: "performer_full_name",
          label: "Водитель",
          sortable: true,
          thStyle: { width: "200px" },
        },
        {
          key: "performer_transport_model",
          label: "Автомобиль",
          sortable: true,
          thStyle: { width: "150px" },
        },
        {
          key: "performer_transport_number",
          label: "Номер авто",
          sortable: true,
          thStyle: { width: "150px" },
        },
        {
          key: "created_at",
          label: "Дата",
          sortable: true,
          thStyle: { width: "150px" },
        },
        //{ key: '', label: 'Время подразделения', sortable: true, thStyle: { width: '150px' } },
        {
          key: "is_active",
          label: "Статус",
          sortable: true,
          thStyle: { width: "150px" },
        },
        {
          key: "district",
          label: "Район",
          sortable: true,
          thStyle: { width: "250px" },
        },
        {
          key: "performer_lng",
          label: "Широта",
          sortable: true,
          thStyle: { width: "200px" },
        },
        {
          key: "performer_lat",
          label: "Долгота",
          sortable: true,
          thStyle: { width: "200px" },
        },
        // { key: '', label: 'Пользователь', sortable: true, thStyle: { width: '200px' } },
        //{ key: '', label: 'ID Заказа', sortable: true, thStyle: { width: '150px' } },
        // { key: 'filterAfto', label: 'Фильтр автоназначения', sortable: true, thStyle: { width: '150px' } },
        // { key: '', label: 'Состаяние GPS', sortable: true, thStyle: { width: '150px' } },
        // { key: '', label: 'Актуалный', sortable: true, thStyle: { width: '150px' } },
      ],
    };
  },
  mounted() {
    resizeable();
  },
  methods: {
    getPerformer(data) {
      this.$router.push({
        name: "driver-performer",
        query: { filter: data.item.performer_id },
      });
      this.$store.commit("draggableTab/ADD_TAB", [
        "Водитель " + data.item.performer_id,
        `/driver-performer`,
        data.item.performer_id,
      ]);
    },
    getDriver(data) {
      this.$router.push({
        name: "car-performer",
        query: { filter: data.item.performer_transport_id },
      });
      this.$store.commit("draggableTab/ADD_TAB", [
        "Автомобиля " + data.item.performer_transport_id,
        `/car-performer`,
        data.item.performer_transport_id,
      ]);
    },
    clicked(item, index, event) {
      this.id = item;
      this.$store.state.filterPlus.tableIndex = index;
      const clickedElement = event.target;
      const clickedCell = clickedElement.closest("td");

      if (!clickedCell) {
        return;
      }

      const columnIndex = Array.from(clickedCell.parentNode.children).indexOf(
        clickedCell
      );

      if (columnIndex < 0 || columnIndex >= this.fields.length) {
        return;
      }

      const clickedField = this.fields[columnIndex];
      this.$store.state.filterPlus.keyFilter = clickedField.key;
      this.$store.state.filterPlus.filterPlus = event.target.innerHTML.trim();
      cellSelect();
    },
  },
};
</script>
<style scoped lang="scss">
@import "@/assets/scss/table.scss";
.my-card .card-body {
  padding: 0px;
}
.my-card {
  overflow: auto;
  height: calc(100vh - 92px);
}
@media (max-height: 700px) {
  .my-card {
    overflow: auto;
    height: 530px;
  }
}
</style>
